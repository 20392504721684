import React from "react";
import { graphql } from "gatsby";
import Homepage from "../components/homepage";

export const query = graphql`
  query($formatString: String!) {
    allPost(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        title
        date(formatString: $formatString)
        excerpt
        timeToRead
        description
        tags {
          name
          slug
        }
      }
    }
  }
`;

type Props = {
  data: {
    allPost: any;
    [key: string]: string;
  };
};

export default function MinimalBlogCoreHomepage({ data }: Props) {
  const { allPost } = data;

  return <Homepage posts={allPost.nodes} />;
}
